@font-face {
  font-family: 'Digital-7';
  src: url(./fonts/digital-7.ttf) format('truetype');
}

html {
  font-size: 16px;
}

@media only screen and (max-width: 1400px) {

  html,
  body {
    font-size: 14px;
  }
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  background-color: #fff;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  border-radius: 5px;
  height: 5px;
  ;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #88888856;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #88888856;
}

#root {
  height: 100%;
  background: white;
}

.page-overflow {
  height: 100%;
  max-height: 100%;
  overflow: scroll;
}

p {
  margin: 0;
}

button {
  background: transparent;
  border: none;
  outline: none;
  padding: 0;
}

button:hover {
  cursor: pointer;
}

.ant-menu.ant-menu-root.ant-menu-vertical {
  height: 100%;
  border: none;
}

.ant-layout-header,
.ant-layout-header-cus {
  height: 5rem;
  max-height: 6.5rem;
}

.ant-layout-footer,
.ant-layout-footer-cus {
  height: 6.375rem;
  max-height: 6.5rem;
}

.ant-content-overflow {
  overflow-y: auto;
}

.ant-modal .ant-modal-content {
  padding: 0;
}

.ant-modal .ant-modal-content .ant-modal-header {
  border-radius: 5px 5px 0px 0px;
  background: var(--primary-button, #FFE5E7);
  padding: 12px 0;
}

.ant-modal .ant-modal-content .ant-modal-header .ant-modal-title {
  color: var(--text-text-3, #1D2129);
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.ant-modal .ant-modal-content .ant-modal-body {
  margin: 0 6%;
  padding: 1.5rem 6%;
  padding-bottom: 0.75rem;
  max-height: 90vh;
  overflow-y: auto;
}

.ant-modal .ant-modal-content .ant-modal-footer {
  padding: 0.75rem 12%;
  padding-bottom: 1.5rem;
  margin-top: 0;
  display: flex;
}

.ant-modal .ant-modal-content .ant-modal-footer .ant-btn {
  height: 3.5rem;
  flex: 1;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.ant-modal .ant-modal-content .ant-modal-footer .ant-btn:disabled {
  opacity: 0.5;
}

.ant-modal .ant-modal-content .ant-modal-footer .ant-btn.ant-btn-primary {
  color: #1D2129;
  background-color: #FF8890;
  border: 1px solid #F5767F;
}

.ant-modal .ant-modal-content .ant-modal-footer .ant-btn.ant-btn-primary:hover {
  border: 1px solid #F5767F;
  background-color: #F5767F;
}

.ant-modal .ant-modal-content .ant-modal-footer .ant-btn.ant-btn-primary:disabled {
  background-color: #FF8890;
  border: 1px solid #F5767F;
}

.ant-modal .ant-modal-content .ant-modal-footer .ant-btn.ant-btn-default {
  color: #1D2129;
  background-color: #E5E6EB;
  border: 1px solid #86909C;
}

.ant-modal .ant-modal-content .ant-modal-footer .ant-btn.ant-btn-default:hover {
  border: 1px solid #86909C;
  background-color: #ADADAD;
}

.ant-modal .ant-modal-content .ant-modal-footer .ant-btn.ant-btn-default:disabled {
  background-color: #E5E6EB;
  border: 1px solid #86909C;
}

.ant-modal .ant-modal-content .ant-modal-footer .ant-btn.hidden {
  display: none;
}

button,
.prevent-select {
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}


.ant-col.ant-form-item-label {
  height: auto;
  padding: 0;
  margin-bottom: 8px;
}

.ant-form-item.mb-0 {
  margin-bottom: 0;
}

.ant-input-password,
.ant-input-number,
.ant-input,
.ant-picker,
.ant-select {
  height: 3.5rem;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.ant-select .ant-select-selector {
  height: 100% !important;
  display: flex;
  align-items: center;
}

.ant-select-selector input {
  font-size: 1.125rem;
}

.ant-select-selector .ant-select-selection-search {
  display: flex;
  align-items: center;
}

.ant-input-sm,
.ant-picker-small,
.ant-select-sm {
  height: 2.5rem;
}

.ant-input,
.ant-input-suffix {
  height: 3rem;
}


.ant-input-lg,
.ant-select-lg,
.ant-picker-large {
  height: 4rem;
}

.ant-picker {
  width: 100%;
}

.ant-form-item.actions,
.ant-form-item.no-margin {
  margin-bottom: 0;
}

.ant-form-item .ant-form-item-label>label,
.ant-form-item-label label,
.ant-form-item-label label.basic_email {
  color: var(--text-text-3, #1D2129);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.ant-form-item .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: none;
}

.ant-input-affix-wrapper.ant-input-password {
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  overflow: hidden;
}

.ant-input-affix-wrapper.ant-input-password .ant-input-suffix {
  width: 4rem;
  background: var(--fill-fill-5, #E5E6EB);
  display: flex;
  padding: 0px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}

.ant-input-affix-wrapper.ant-input-password .ant-input-suffix .ant-input-password-icon {
  font-size: 1.25rem;
  color: var(--fill-fill-4, #1D2129);
}

.ant-select-selection-search .ant-input-group-addon button {
  width: 100%;
  height: 3.5rem;
}

.custom-option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 0 1rem;
}

.ant-select-selection-item .ant-checkbox {
  display: none;
}

.ant-select-dropdown .ant-select-item-option-state {
  display: none;
}

.text-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.modal-add-new-appointment.ant-modal .ant-modal-content .ant-modal-body,
.modal-appointment-detail .ant-modal-body {
  overflow: hidden;
}

.modal-vip.ant-modal .ant-modal-content .ant-modal-body,
.modal-split-ticket.ant-modal .ant-modal-content .ant-modal-body,
.modal-overflow-unset.ant-modal .ant-modal-content .ant-modal-body,
.modal-signature.ant-modal .ant-modal-content .ant-modal-body {
  overflow-y: unset;
}

.modal-max-height-unset.ant-modal .ant-modal-content .ant-modal-body,
.modal-check.ant-modal .ant-modal-content .ant-modal-body {
  max-height: unset !important;
}

.modal-form-input-check {
  max-width: 60rem;
}

.ant-modal-content {
  opacity: 0.9;
}

.setting-popover.ant-popover .ant-popover-inner {
  padding: 0;
  border-radius: 4px;
  border: 1px solid var(--line-line-3, #86909C);
  background: var(--button-bg-5, #FFF);

  /* Shadow active */
  box-shadow: 0px 0px 20px 0px rgba(95, 186, 203, 0.15);
  overflow: hidden;
  padding: 4px 0;
}

.setting-popover.ant-popover .ant-popover-arrow:before {
  background: #86909C;
}

.modal-add-tip.ant-modal-root .ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.7);
}

@media print {
  pre, blockquote {page-break-inside: avoid;}
}